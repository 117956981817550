body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
html {
  overflow-x: hidden;
}
.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 24px;
  padding-left: 0px;
}

.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 35px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #156a8f;
  background-color: #156a8f !important;
}

.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
  padding: 10px;
}
.Navbar {
  position: fixed;
  background-color: transparent;
  width: 100%;
  z-index: 111;
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.Navbar.scrolled {
  background-color: #00041b;
  transform: translateY(-100%);
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.fadeInUp.delay-1s {
  animation-delay: 1s;
}

.fadeInUp.delay-2s {
  animation-delay: 2s;
}
.Navbar.show {
  transform: translateY(0);
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes moveLeftRight {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}

.slider-three__shape-1 {
  width: 17px;
  height: 120px;
  background-image: url(./Component/assets/images/RI/s1.png);
  position: absolute;
  left: 70px;
  top: 35%;
  animation: moveUpDown 3s ease-in-out infinite;
}

.slider-three__shape-2 {
  position: absolute;
  bottom: 40%;
  left: 35%;
  width: 20px;
  height: 20px;
  background-image: url(./Component/assets/images/RI/s2.png);
  animation: moveLeftRight 3s ease-in-out infinite;
}

.slider-three__shape-3 {
  position: absolute;
  bottom: 50%;
  left: 187px;
  width: 85px;
  height: 24px;
  background-image: url(./Component/assets/images/RI/s3.png);
  animation: moveUpDown 3s ease-in-out infinite;
  display: none;
}

.slider-three__shape-4 {
  position: absolute;
  top: 30%;
  left: 30%;
  width: 79px;
  height: 64px;
  background-image: url(./Component/assets/images/RI/s4.png);
  animation: moveLeftRight 3s ease-in-out infinite;
}
.Navbar-c {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 50%;
}
.logo img {
  width: 90%;
}
.btn-group a {
  background-image: linear-gradient(white, white),
    linear-gradient(#55af64, #55af64);
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #f26a32;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}

/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.slider-three {
  background-image: url(../src/Component/assets/images/RI/2.webp);
  background-repeat: no-repeat;
  background-position: top left;
  /* padding: 0% 8%; */
  /* padding-right: 0%; */
  padding-top: 138px;
  padding-bottom: 106px;
  position: relative;
  margin-bottom: 100px;
}
.slider-three__title {
  font-size: 50px;
  color: white;
  margin: 0;
}
.slider-three__text {
  font-size: 18px;
  color: white;
}
.sec-title__tagline {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 700;
  color: var(--insuco-base, #00accc);
  margin: 0;
  line-height: 1em;
  margin-bottom: 16px;
}
.sec-title__title {
  font-size: 42px;
  line-height: 1.19em;
  text-align: center;
  font-weight: bold;
  color: #00041b;
}
.HowWorks .sec-title__tagline {
  color: white;
  text-align: left;
}
.HowWorks .sec-title__title {
  font-size: 42px;
  line-height: 1.19em;
  text-align: left;
  font-weight: bold;
  color: #ffffff;
  margin-top: 0px;
}
.HowWorks ul li {
  list-style: none;
  margin-top: 20px;
}
.about-three__list__title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 9px;
}
.about-three__list__text {
  color: #fff;
}
.service-card-four__title a {
  text-decoration: none;
  font-size: 22px;
  color: #313133;
}
.service-card-four__text {
  color: #616161;
  line-height: 22px;
}
.containerHero {
  width: 100%;
  padding-left: 8%;
}
.rowHero {
  display: flex;
  align-items: center;
  gap: 30px;
}
.col1Hero {
  width: 40%;
}
.col2Hero {
  width: 60%;
}
.col2Hero img {
  /* width: 100%; */
  border-radius: 32px;
}
.Services {
  padding: 0% 8%;
  margin-bottom: 20px;
}
.Services .btn-group {
  margin-top: 30px;
  justify-content: center;
}

.rowService {
  display: flex;
  gap: 30px;
}
.col-md-6 {
  /* flex:  0 0 auto; */
  width: 25%;
}
.service-card-four {
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 32, 110, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 32, 110, 0.07);
  padding: 20px;
  padding-top: 40px;
  position: relative;
  height: 88%;
}
.service-card-four:hover {
  background-image: linear-gradient(
    90deg,
    var(--insuco-primary, #f26a32) 0%,
    var(--insuco-base, #f26a32) 100%
  );
  transition: 0.5s ease;
}
.service-card-four:hover .service-card-four__title a {
  color: white;
}
.service-card-four:hover .service-card-four__text {
  color: white;
}
.service-card-four:hover .service-card-four__icon img {
  filter: invert(100%);
}
.service-card-four__circle {
  width: 65px;
  height: 65px;
  background-color: rgb(7, 20, 77);
  opacity: 0.1;
  position: absolute;
  top: 5%;
  left: 5%;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-card-four__bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(./Component/assets/images/service-card-4-1.png);
  background-repeat: no-repeat;
  background-position: top right;
}

.service-card-four:hover .service-card-four__circle {
  width: 65px;
  height: 65px;
  background-color: white;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.p-c {
  color: white;
  font-weight: 500;
  line-height: 25px;
}
.HowWorks {
  position: relative;
  padding: 4% 8%;
}
.about-three__bg {
  background-image: url(./Component/assets/images/elderly-woman-visiting-grave-loved-one.webp);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}
.about-three__bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    var(--insuco-primary, #f26a32) 0%,
    var(--insuco-base, #f26a32) 100%
  );
  opacity: 0.9;
}
.about-three .container {
  position: relative;
}
.rowHowWorks {
  display: flex;
  align-items: center;
  gap: 50px;
}
.rowHowWorks .col-lg-6 {
  width: 50%;
}
.rowHowWorks .col-lg-6 img {
  width: 100%;
  border-radius: 32px;
}
.Contact {
  padding: 0% 8%;
  margin-top: 30px;
}
.ContactFlex {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: hsl(206deg 70.12% 39.89%);
  gap: 50px;
  position: relative;
  z-index: 1;
}
.contactBgImage {
  width: 40%;
}
.service-card-four__icon img {
  width: 70px;
  margin-left: 20px;
}
.ContactContent h2 {
  color: white;
}
.ContactContent {
  width: 60%;
  margin-left: 40%;
  padding: 40px;
}

.Footer {
  padding: 5px 8%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
}

.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Footer1 img {
  margin-top: 40px;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}

.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.contactBgImage {
  background-size: cover;
  background-position: center center;
  height: 100%;
  position: absolute;
  z-index: 5;
}
.nb {
  padding: 10%;
  padding-top: 8%;
  padding-bottom: 2%;
  background-color: #f26a32;
  color: white;
}
.elementor-heading-title {
  color: #f26a32;
}
.nc li strong {
  color: #f26a32;
}
.nc a {
  color: #f26a32;
  text-decoration: none;
}
.nb a {
  color: white;
  text-decoration: none;
}
.nb .elementor-heading-title {
  color: white;
}
.nc {
  padding: 0% 10%;
}
br{
  display: none;
}
@media (max-width: 768px) {
  .Navbar {
    display: none;
  }
  .containerHero {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .col2Hero img {
    width: 100%;
  }

  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }

  .Footer span {
    padding-bottom: 90px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: #4aa0d0;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
  }
  /* Root variables for easy customization */
  :root {
    --btn-border: 1px solid rgba(255, 255, 255, 0.2);
    --btn-bg: #f26a32; /* Background color for the button */
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
    --btn-text-color: #f4f4f4;
    --shine-color: #79aabe; /* Prominent shine */
    --shine-degree: -45deg;
    --shine-duration: 5s; /* Duration of the shine animation */
  }

  /* Styling for the button */
  .bottomFixed a {
    text-decoration: none;
    color: var(--btn-text-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--btn-bg);
    width: 90%;
    display: block;
    margin: 0 auto;
    height: 90%;
    padding: 15px 0;
    border-radius: 10px;
    position: relative; /* Necessary for ::before positioning */
    overflow: hidden; /* Hide the overflow for the shine effect */
    border: var(--btn-border);
  }

  /* Shining animation */
  .bottomFixed a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start off screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      var(--shine-degree),
      transparent 40%,
      /* Start the transparent part */ var(--shine-color) 50%,
      /* Narrower white shine */ transparent 75% /* End the transparent part */
    );
    animation: shine var(--shine-duration) linear infinite;
  }

  /* Keyframes for the shine animation */
  @keyframes shine {
    0% {
      left: -150%; /* Start off screen */
    }
    50% {
      left: 150%; /* Move across the button */
    }
    50.01% {
      left: -150%; /* Restart immediately */
    }
    100% {
      left: 150%; /* Move across the button */
    }
  }

  .MobileNavbar {
    display: block;
  }

  .btn-group a {
    padding: 12px 4px;
    font-size: 20px;
  }

  .btn-group {
    padding: 30px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .rowHero {
    flex-direction: column;
  }
  .col1Hero {
    width: 100%;
  }
  .col2Hero {
    width: 100%;
  }
  .slider-three {
    padding: 0% 8%;
    margin-bottom: 50px;
  }
  .slider-three__title {
    font-size: 28px;
    color: white;
    margin: 0;
    text-align: center;
  }
  .slider-three__text{
    text-align: center;
    line-height: 26px;
  }
  br{
    display: block;
  }
  .topFixed img {
    width: 100%;
  }
  .col1Hero .my-auto {
    margin-top: 30px;
  }
  .rowService {
    flex-direction: column;
  }
  .Services .col-md-6 {
    width: 100%;
  }
  .sec-title__title {
    font-size: 28px;
  }
  .rowHowWorks {
    flex-direction: column;
  }
  .rowHowWorks .col-lg-6 {
    width: 100%;
  }
  .HowWorks .sec-title__title {
    font-size: 28px;
    margin-top: 0px;
  }
  .ContactFlex {
    flex-direction: column;
  }
  .contactBgImage {
    background-size: cover;
    background-position: center center;
    height: 50%;
    position: absolute;
    z-index: 5;
    width: 100%;
  }
  .ContactContent {
    width: 100%;
    margin-left: 0%;
    padding: 40px;
    margin-top: 50%;
  }
  .ContactContent h2 {
    color: white;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .ContactFlex .btn-group {
    width: 90%;
    margin: 0 auto;
  }
  .Footer1 img {
    margin-top: 40px;
    width: 100%;
  }
  .rowHero .btn-group {
    display: none;
  }
  .slider-three__shape-1,
  .slider-three__shape-2,
  .slider-three__shape-3,
  .slider-three__shape-4 {
    display: none;
  }
}
